@charset "UTF-8";

:root,
[data-bs-theme=light] {
  --bs-blue: #0D2C54;
  --bs-red: #B80D0D;
  --bs-orange: #fd7e14;
  --bs-yellow: #F5B300;
  --bs-green: #009E3D;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #7F8A8C;
  --bs-gray-dark: #3B3F4B;
  --bs-gray-100: #F2F7FA;
  --bs-gray-200: #E6EBEE;
  --bs-gray-300: #D0D7D9;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #A9B7B9;
  --bs-gray-600: #7F8A8C;
  --bs-gray-700: #4E5A5C;
  --bs-gray-800: #3B3F4B;
  --bs-gray-900: #282B33;
  --bs-primary: #0D2C54;
  --bs-secondary: #7F8A8C;
  --bs-success: #009E3D;
  --bs-info: #0dcaf0;
  --bs-warning: #F5B300;
  --bs-danger: #B80D0D;
  --bs-light: #F2F7FA;
  --bs-dark: #282B33;
  --bs-primary-rgb: 13, 44, 84;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 0, 158, 61;
  --bs-info-rgb: 162, 199, 245;
  --bs-warning-rgb: 245, 179, 0;
  --bs-danger-rgb: 184, 13, 13;
  --bs-light-rgb: 230, 235, 238;
  --bs-dark-rgb: 40, 43, 51;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #4E5A5C;
  --bs-dark-text-emphasis: #4E5A5C;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #E6EBEE;
  --bs-dark-border-subtle: #A9B7B9;
  --bs-font-sans-serif: "Poppins", "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: var(--bs-gray-900);
  --bs-body-color-rgb: 40, 43, 51;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 30, 87, 140;
  --bs-secondary-color: rgba(40, 43, 51, 0.75);
  --bs-secondary-color-rgb: 40, 43, 51;
  --bs-secondary-bg: #e6ebee;
  --bs-secondary-bg-rgb: 231, 236, 238;
  --bs-link-color: #0d2c54;
  --bs-link-color-rgb: 13, 44, 84;
  --bs-link-decoration: none;
  --bs-link-hover-color: rgb(30, 87, 140);
  --bs-link-hover-color-rgb: 30, 87, 140;
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.65rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.65rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-form-valid-color: #009E3D;
  --bs-form-valid-border-color: #009E3D;
  --bs-form-invalid-color: #B80D0D;
  --bs-form-invalid-border-color: #B80D0D;
  --bs-heading-color: #0D2C54;
  letter-spacing: 0.0125em;
  --bs-border-color: #D0D7D9;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-gray-900);
}

h4,
.h4 {
  line-height: 1.3;
}

h5,
.h5 {
  font-size: 1.125rem;
  line-height: 1.4;
}

h6,
.h6 {
  line-height: 1.4;
}

a,
.btn-link {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 1;
  border-color: var(--bs-gray-400);
}

.btn {
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.5;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-box-shadow: none;
  --bs-btn-font-size: 0.875rem;
}

.btn i.fa-arrow-right {
  position: relative;
  margin-left: 0.25rem;
  left: 0;
}

.btn:hover i.fa-arrow-right,
.btn:focus i.fa-arrow-right,
.btn:active i.fa-arrow-right {
  left: 0.25rem;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #164376;
  --bs-btn-border-color: #164376;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0D2C54;
  --bs-btn-hover-border-color: #0D2C54;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0D2C54;
  --bs-btn-active-border-color: #0D2C54;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #164376;
  --bs-btn-disabled-border-color: #164376;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #009E3D;
  --bs-btn-border-color: #009E3D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #007C2E;
  --bs-btn-hover-border-color: #007C2E;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #007C2E;
  --bs-btn-active-border-color: #007C2E;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #009E3D;
  --bs-btn-disabled-border-color: #009E3D;
}

.btn-info {
  --bs-btn-color: #0D2C54;
  --bs-btn-bg: #A2C7F5;
  --bs-btn-border-color: #A2C7F5;
  --bs-btn-hover-color: #0D2C54;
  --bs-btn-hover-bg: #8FB2DE;
  --bs-btn-hover-border-color: #8FB2DE;
  --bs-btn-active-color: #0D2C54;
  --bs-btn-active-bg: #8FB2DE;
  --bs-btn-active-border-color: #8FB2DE;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #0D2C54;
  --bs-btn-disabled-bg: #A2C7F5;
  --bs-btn-disabled-border-color: #A2C7F5;
}

.btn-warning {
  --bs-btn-color: #282B33;
  --bs-btn-bg: #FAD56F;
  --bs-btn-border-color: #FAD56F;
  --bs-btn-hover-color: #282B33;
  --bs-btn-hover-bg: #F5B300;
  --bs-btn-hover-border-color: #F5B300;
  --bs-btn-active-color: #282B33;
  --bs-btn-active-bg: #F5B300;
  --bs-btn-active-border-color: #F5B300;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #282B33;
  --bs-btn-disabled-bg: #FAD56F;
  --bs-btn-disabled-border-color: #FAD56F;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #E55C5C;
  --bs-btn-border-color: #E55C5C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B80D0D;
  --bs-btn-hover-border-color: #B80D0D;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B80D0D;
  --bs-btn-active-border-color: #B80D0D;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #E55C5C;
  --bs-btn-disabled-border-color: #E55C5C;
}

.btn-light {
  --bs-btn-color: #0D2C54;
  --bs-btn-bg: #E6EBEE;
  --bs-btn-border-color: #E6EBEE;
  --bs-btn-hover-color: #0D2C54;
  --bs-btn-hover-bg: #D0D7D9;
  --bs-btn-hover-border-color: #D0D7D9;
  --bs-btn-active-color: #0D2C54;
  --bs-btn-active-bg: #D0D7D9;
  --bs-btn-active-border-color: #D0D7D9;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #0D2C54;
  --bs-btn-disabled-bg: #E6EBEE;
  --bs-btn-disabled-border-color: #E6EBEE;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #282B33;
  --bs-btn-border-color: #282B33;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #14151B;
  --bs-btn-hover-border-color: #14151B;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #14151B;
  --bs-btn-active-border-color: #14151B;
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #282B33;
  --bs-btn-disabled-border-color: #282B33;
}

b,
strong,
.fw-bold {
  font-weight: 600 !important;
}

.badge {
  --bs-badge-font-size: 0.875em;
  --bs-badge-font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 0.05em;
}

.table {
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: var(--bs-gray-200);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: var(--bs-gray-200);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-body-color);
}

/* Custom Badges */
.bg-light {
  background-color: var(--bs-gray-100);
}

.bg-video,
.badge-success {
  color: #007C2E;
  background-color: #A5E5B5;
}

.bg-tutorial,
.badge-warning {
  color: #B17800;
  background-color: #FEF2C2;
}

.bg-surgery,
.badge-info {
  color: #164376;
  background-color: #DAE7F6;
}

.bg-cme,
.badge-danger {
  color: #B80D0D;
  background-color: #FBC2C2;
}

.bg-observership,
.bg-recording {
  color: #B33C71;
  background-color: #FDD5E4;
}

.bg-workshop {
  color: #692D9B;
  background-color: #DCC5F0;
}

/* Shadow */
.shadow {
  box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/* Header */
.navbar-brand .nav-logo {
  width: 160px;
}

@media all and (max-width:1399px) {
  .navbar-brand {
    margin-right: 1rem;
  }

  .navbar-brand .nav-logo {
    width: 121px;
  }
}

@media all and (max-width:1199px) {
  .navbar-brand {
    margin-right: auto;
  }

  .navbar-brand .nav-logo {
    width: 140px;
  }
}

.navbar {
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.25rem;
  --bs-navbar-toggler-font-size: 1rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
}

.navbar-toggler {
  border: none;
  outline: none !important;
  box-shadow: none !important;
  color: var(--bs-primary);
}

.navbar-toggler i {
  font-size: 1.725rem;
}

.nav-link {
  font-size: var(--bs-body-font-size);
  font-weight: 600;
  color: var(--bs-link-color);
}

.dropdown-menu {
  --bs-dropdown-font-size: var(--bs-body-font-size);
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-link-color: var(--bs-link-color);
  --bs-dropdown-link-hover-color: var(--bs-link-hover-color);
  --bs-dropdown-link-hover-bg: var(--bs-gray-200);
  --bs-dropdown-link-active-color: var(--bs-link-color);
  --bs-dropdown-link-active-bg: var(--bs-gray-300);
  --bs-dropdown-link-disabled-color: var(--bs-gray-200);
  --bs-dropdown-border-width: 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.dropdown-item {
  font-weight: 600;
  color: var(--bs-link-color);
  padding: 12px 22px;
  white-space: unset;
}

.dash-header-wrap .dropdown-menu {
  min-width: 240px;
  max-width: 320px;
}

.dash-header-wrap .dropdown-item .badge {
  height: 20px;
}

.dropdown-toggle::after {
  margin-left: -2px;
  vertical-align: bottom;
  font-family: "keenicons-solid";
  content: "\e9e5";
  border: 0;
}

@media all and (max-width: 1199px) {
  .dash-header-wrap .dropdown-menu {
    max-width: 100%;
  }

  .dash-header-wrap .navbar-nav .dropdown-menu {
    box-shadow: none !important;
    background-color: var(--bs-gray-200);
    margin-bottom: 4px;
  }

  .dropdown-item {
    font-weight: 600;
    color: var(--bs-link-color);
    padding: 7px 15px;
  }
}

.nav-back-button i {
  font-size: 1.5rem;
}

.card {
  --bs-card-border-radius: var(--bs-border-radius-lg);
}

/* Notification */
.notification-panel .notification-icon {
  position: relative;
}
.notification-panel .notification-icon .ki-notification {
  font-size: 22px;
}
.notification-panel .notification-icon .badge {
  border-radius: 50px !important;
  margin-left:5px;
  line-height: 15px;
  font-size: 12px;
  background-color: #A2C7F5 !important;
  color: #222;
  position: absolute;
  top: -12px;
  right: -8px;
}
.notification-panel .notification-icon span {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #A2C7F5;
  display: inline-block;
  right: 4px;
}

.notification-desc-wrap {
  max-height: 220px;
  overflow: scroll;
}

.user-image-wrap {
  border-radius: 50rem;
  overflow: hidden;
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  position: relative;
}

.user-image-lg {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.user-image-wrap img {
  width: 44px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.user-image-lg img {
  width: 120px;
}

/* Main */
main.main {
  padding-top: 70px;
  min-height: calc(94vh);
}

.custom-breadcrumb .breadcrumb-item {
  color: var(--bs-gray-400);
  font-size: 0.825rem;
}

.custom-breadcrumb .breadcrumb-item a {
  color: var(--bs-gray-100);
}

/* Icon Box */
.icon-box {
  position: relative;
}

.icon-box-surgery {
  /* background-color: #DAE7F6; */
  /* background-color: #0D2C54; */
  background-color: #000684;
}

.icon-box-livesurgery {
  /* background-color: #DAE7F6; */
  background-color: #007bff;
}

.icon-box-video {
  /* background-color: #A5E5B5; */
  background-color: #4cba1f;
}

.icon-box-tutorial {
  background-color: #FFD1A6;
}

.icon-box-cme {
  /* background-color: #FBC2C2; */
  /* background-color: #0D2C54; */
  background-color: #f64e60;
}

.icon-box-observership {
  background-color: #FDD5E4;
}

.icon-box-workshop {
  background-color: #DCC5F0;
}

.icon-box .btn-link {
  position: absolute;
  bottom: 1.25rem;
  color: #fff !important;
}

.icon-box .btn-link.text-left {
  text-align: left !important;
}

.icon-box:hover i.fa-arrow-right,
.icon-box:focus i.fa-arrow-right,
.icon-box:active i.fa-arrow-right {
  left: 0.25rem;
}

.icon-box-dashboard {
  display: flex;
  align-items: center;
}
.showcas_flex {
  display: flex;
  align-items: center;
}
.icon-box-dashboard h6{
  color: #fff;
  font-size: 18px;
}
.icon-box-dashboard img {
  margin-right: 15px;
  width: 44px;
  height: 44px;
}

.icon-box-association {
  display: flex;
  align-items: center;
}
.icon-box-association .showcas_flex {
  display: block;
  text-align: center;
}
.icon-box-association img {
  margin-bottom: 15px;
}

@media all and (max-width: 767px) {
  .icon-box-dashboard {
    display: block;
    background: none !important;
  }
  .icon-box-dashboard img {
    margin-right: 0;
  }  
  .showcas_flex {
    display: block;
    text-align: center;
  }
  .icon-box-dashboard h6{
    color: var(--bs-primary);
    font-size: 13px;
  }
}

/* Video */
.video-thumb-wrap {
  position: relative;
}

.video-thumb-wrap img {
  position: relative;
  z-index: 1;
}

.video-thumb-wrap i {
  width: 44px;
  height: 44px;
  background-color: #fff;
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  color: #0D2C54;
  font-size: 18px;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 44px;
  border-radius: 50rem;
}

.video-thumb-wrap:hover i {
  background-color: #0D2C54;
  color: #fff;
}

/* CME */
thead th {
  font-weight: 600;
}

table.cme-table thead th {
  color: var(--bs-gray-700);
}

table.cme-table td {
  padding: 1em 0.5em;
}

table.cme-table .text-muted {
  color: var(--bs-gray-500);
}

@media all and (max-width: 991px) {
  table.cme-table thead {
    display: none;
  }

  table.cme-table tr {
    display: block;
  }

  table.cme-table tbody td:first-child {
    padding-top: 1rem;
  }

  table.cme-table tbody td:last-child {
    padding-bottom: 1.25rem;
  }

  table.cme-table tbody td {
    display: block;
  }

  table.cme-table td {
    padding: 0.5em 1em;
  }
}

/* Event */
.event-card {
  position: relative;
}

/* .event-card .btn {
  position: absolute;
  bottom: 1.25rem;
} */

/* Form */
.form-label {
  font-weight: 500;
  color: var(--bs-gray-900);
  margin-bottom: 3px;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
  background-color: var(--bs-gray-100);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  background-color: var(--bs-gray-100);
}

.input-group-text {
  font-size: 0.875rem;
}

.form-control,
.form-select {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  background-color: var(--bs-gray-100);
}

.form-control:hover,
.form-select:hover {
  border-color: var(--bs-gray-500);
}

.form-control:focus,
.form-select:focus {
  border-color: var(--bs-gray-500);
  outline: 0;
  box-shadow: 0 0 0.25rem var(--bs-gray-500);
}

.form-text {
  color: var(--bs-gray-700);
}

/* Event */
@media all and (max-width: 991px) {
  .event-quick-info-wrap .border-end {
    border: none !important;
  }
}

/* Videos */
.circle-seperator {
  font-size: 0.35rem;
  color: var(--bs-gray-600);
  vertical-align: middle;
}

.comment-author .author-name {
  font-size: 0.8rem;
}

/* Transition */
.video-thumb-wrap i,
.btn i.fa-arrow-right,
.form-control,
.form-select {
  transition: all 0.15s ease-in-out;
}

/* custom css start */
.breadcrumb-item.active {
  position: relative;
  padding-left: 20px !important;
}

.breadcrumb-item.active::before {
  content: '\f054' !important;
  font-weight: 600;
  font-family: 'Font Awesome 5 Free';
  color: rgb(108 117 125) !important;
  font-size: 10px;
  position: absolute;
  left: 7px;
  top: 2px;
}

.dropdown-toggle::after {
  content: '\f078' !important;
  font-weight: 600 !important;
  font-family: "Font Awesome 5 Free" !important;
  font-size: 10px;
  margin-left: 4px !important;
  vertical-align: middle !important;
}

.otp_label {
  padding-left: 62px;
}

.otp_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash_box button {
  font-size: 13px !important;
  padding: 5px 10px !important;
}
.new_gal {
  display: block !important;
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 15px;
}
.new_gal .col-6 {
  display: inline-block;
}
.new_gal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.new_gal::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
  height: 10px;
  border-radius: 10px;
}

.new_gal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(122, 153, 217)),
      color-stop(0.72, rgb(73, 125, 189)),
      color-stop(0.86, rgb(28, 58, 148)));
}


@media (max-width: 767px) {
  .otp_box input {
    margin: 0px 4px;
  }

  .mobile_slide {
    white-space: nowrap;
    overflow-x: auto;
    display: block !important;
  }

  .mobile_slide .align-items-stretch {
    width: 75%;
    display: inline-block !important;
    white-space: normal;
  }

  .mobile_slide .align-items-stretch iframe {
    width: 100%;
  }

  .dash_box {
    padding: 0px 5px;
  }

  .dash_box .col-4 {
    padding: 5px;
  }
.mobile_hide {
  display: none !important;
}
}

@media (min-width: 768px) {
  .otp_box input {
    width: 60px;
    height: 60px;
  }

  .otp_padding {
    padding: 0% 10%;
  }

  .dash_box button {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }
  .dash_showcase button {
    right: 10px;
  }
  .less_margin {
    margin: 5px 0px !important; 
  }
}