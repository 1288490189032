/* Loader.css */

.custom-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    z-index: 999; /* Set a high z-index to appear above other content */
  }
  
  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-circle-container {
    display: flex;
    align-items: flex-end;
  }
  
  .custom-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;
    animation: custom-changeColor 2s infinite linear;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .custom-circle:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .custom-circle:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes custom-changeColor {
    0%, 100% {
      background-color: #0D2C54;
    }
    25% {
      background-color: #1B4F97;
    }
    50% {
      background-color: #4084C5;
    }
    75% {
      background-color: #619AD3;
    }
  }
  