.custom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .custom-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .custom-text {
    flex: 1;
    text-align: center;
  }
  
  .custom-title {
    font-size: 6rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .custom-subtitle {
    font-size: 3rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .custom-message {
    font-size: 1.5rem;
    color: #777;
    margin-bottom: 2rem;
  }
  
  
  
